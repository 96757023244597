import React, { Component } from 'react';
class ServiceBlock extends Component {
   
    render() {
        return (
            <div className='grid md:grid-cols-2 gap-5'>
                <div className='relative md:h-80'>
                    <div className='relative h-full w-full primaryBackground overflow-hidden'>
                        <img src={this.props.img} alt={this.props.alt} className='w-11/12 h-full object-cover box-border' />
                    </div>
                </div>

                <div className='flex flex-col justify-between items-start'>
                    <div className='mb-5 md:mb-0'>
                        <h3>
                            {this.props.title}
                        </h3>
                        <p>
                            {this.props.p}

                        </p>
                    </div>
                    {/* <OutlinedButton text={this.props.buttonText} />*/} 
                    </div>
            </div>
        );
    }
}

export default ServiceBlock;