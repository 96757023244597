import React, { Component } from 'react';


class LmtLogoWhite extends Component {
    state = {}
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 240.81 109.058">
                <g id="Group_16" data-name="Group 16" transform="translate(1730.431 -940.219)">
                    <g id="Group_12" data-name="Group 12" transform="translate(-1730.431 940.219)">
                        <g id="Group_11" data-name="Group 11" transform="translate(0 0)">
                            <g id="Group_10" data-name="Group 10">
                                <path id="Path_7" data-name="Path 7" d="M-1730.431,940.219h20.929V1013.6h45.349v17.054h-66.278Z" transform="translate(1730.431 -940.219)" fill="#fff" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_15" data-name="Group 15" transform="translate(-1568.431 940.219)">
                        <g id="Group_14" data-name="Group 14" transform="translate(0 0)">
                            <g id="Group_13" data-name="Group 13">
                                <path id="Path_8" data-name="Path 8" d="M-1503.254,957.272h-28.939V940.219h78.81v17.053h-28.94v73.384h-20.93Z" transform="translate(1532.193 -940.219)" fill="#fff" />
                            </g>
                        </g>
                    </g>
                    <path id="Path_9" data-name="Path 9" d="M-1730.431,1071.334v-10.448h240.81l-3.657,10.448Z" transform="translate(0 -22.058)" fill="#fff" />
                    <path id="Path_10" data-name="Path 10" d="M-1554.365,960.541h-3.269V940.219h-11.358l-33.333,56.2-33.85-56.2h-17.312v70.114h6.668v20.323h12.97v-53.1l26.485,43.539h9.432l26.614-44.7.129,54.262h19.639l-.2-70.115Z" transform="translate(-14.065)" fill="#fff" />
                </g>
            </svg>



        );
    }
}

export default LmtLogoWhite;