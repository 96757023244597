import React, { Component } from 'react';
import person from "../images/person.png"
import SecondaryButton from '../secondaryButton';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Hero extends Component {


    render() {
        return (
            <div className='container flex flex-row my-auto md:pt-8 pt-5'>
                <div className="grid md:grid-cols-2 gap-5 items-center hero sm:grid-cols-1">
                    <Zoom>

                        <div className='flex flex-col align-middle gap-8 order-2 md:order-1 z-50'>
                            <h1 className='text-center md:text-left'>
                                "We drive growth, adapt to changes and create value"
                            </h1>

                            <div className="mx-auto md:mx-0">
                                <AnchorLink offset='100' href='#contact'>
                                    <SecondaryButton hero={true} text='I want to improve my business' />
                                </AnchorLink>
                            </div>
                        </div>
                    </Zoom>
                    <Fade right>
                        <div className='mx-auto relative order-1 md:order-2 mt-12 md:mt-0'>
                            <div className='bar'></div>

                            <img src={person} alt='Lucian manager consulting' className="z-10 relative w-3/4 md:w-full mx-auto"></img>
                            <div className='personBar '></div>
                        </div>
                    </Fade>

                </div>
            </div>
        );
    }
}

export default Hero;