import React from 'react';


const Vision = () => {
    //TODO: Create compo
    return (<div className='container mx-auto'>
        <h3 className=' text-center'>VISION</h3>

        <h4 className=' italic text-center'>
            LMT is a customer-focused consulting firm that helps organizations drive growth, adapt to changes and create value.
        </h4>
    </div>)
}

export default Vision;