import React, { Component } from 'react';
import ContactBar from './contactbar';
import CoffeeImage from './images/coffee.png';
import SignedName from './images/signedName';
import SecondaryButton from './secondaryButton';
class Contact extends Component {
    state = {}
    render() {
        return (
            <section>
                <div className='flex flex-col md:flex-row'>
                    <div className='primaryBackground'>
                        <img src={CoffeeImage} alt="Cup of coffee for management consulting" className="h-full object-cover" />
                    </div>
                    <div className='primaryBackground w-full flex flex-col justify-evenly  px-5 pl-5 py-5 '>
                        <h3 className=' text-white'>Let's get connected</h3>
                        <p className=' text-white mb-5 lg:mb-0'>
                            Are you ready for the next step? Together we can drive growth, adapt to changes and create value.
                            <br />
                            <div className=' w-40 my-5 flex justify-start'><SignedName /></div>
                        </p>
                        <div className='mb-5 lg:mb-0'>
                            <a href='mailto:lucian@lmtbiz.com?subject=Cup of coffee&body=Hi there, when can we schedule an appointment?'>
                                <SecondaryButton hero={false} text="I want a consultation" />
                            </a>
                        </div>
                    </div>
                </div>
                <ContactBar />
            </section>
        );
    }
}

export default Contact;