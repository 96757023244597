import React, { Component } from 'react';
import ServiceBlock from './serviceBlock';
import TableImage from '../images/image_table_people.png';
import AnalyseImage from '../images/analyse.png';
import StocksImage from '../images/stocks.png';
import LaptopTable from '../images/laptoptable.png';
import ServiceBlockOpposite from './serviceBlockOpposite';


class Services extends Component {
    state = {}
    render() {
        return (
            <section>
                {/* Heading of service section */}
                <div className='flex flex-col'>
                    <div className='md:mx-52 relative'>

                        <div className='relative z-50 text-center flex flex-col'>
                            <h2>
                                Services
                            </h2>
                            <div className=' z-0 secondaryBar w-full'>

                            </div>
                        </div>
                    </div>
                    <p className='text-center mt-10 max-w-md mx-auto text-2xl'>
                        LMT offers a variety of services such as:
                    </p>
                </div>
                <div className='mt-16'></div>
                {/* Service explanation section */}
                <ServiceBlock title='Management consultancy'
                    p='Day-to-day general business management or a project-based input. Help with transitions and/or organizational transformations. Focused on efficiency and goals achievements.'

                    img={TableImage} buttonText='Lorem ipsum dolor sit' alt="management consulting people table" />
                <div className=' mt-16'></div>
                <ServiceBlockOpposite title='Business Overview' p="Complete assessment of current business or on-going projects. Clarify goals and means to achieve them. Find new ways to improve financial results." img={AnalyseImage} buttonText='Lorem ipsum dolor sit' alt="Hourglass analyzing trade patterns" />
                <div className=' mt-16'></div>
                <ServiceBlock title='Sales & Sales Strategy Review'
                    p="Review of current sales structure. Discuss with the sales group to identify issues and effectiveness. Identify improvement opportunities and new ways to boost profitable sales." img={StocksImage} buttonText='Lorem ipsum dolor sit' alt="Hourglass analyzing trading stocks" />
                <div className=' mt-16'></div>
                <ServiceBlockOpposite title='“Front-line” Personnel Trainings'
                    p={<>"Learn how to communicate with customers on different levels. Identify the decision makers and understand their real needs. Focus on value added features & differentiators resulting in <i>price</i> becoming a secondary factor. Learn how to negotiate a mutually profitable (win-win) agreement that doesn’t necessarily include the old “split-in-half” solution.</>}
                    img={LaptopTable} buttonText='Lorem ipsum dolor sit' alt="People performing management consultancy at a table" />

            </section>

        );
    }
}

export default Services;