import React from 'react';

import LmtLogo from './images/lmtLogo';
import PrimaryButton from './primaryButton';
import { stack as Menu } from 'react-burger-menu'
import AnchorLink from 'react-anchor-link-smooth-scroll'


class NavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuState: false,
            navbar: false
        }
    }



    componentDidMount() {
        window.addEventListener("scroll", (event) => {
            if (window.scrollY >= 66) {
                this.setState(state => ({ navbar: true }))

            } else {
                this.setState(state => ({ navbar: false }))
            }
        })
    }


    handleMenu() {
        this.setState(state => ({ menuState: !state.menuState }))
        console.log(this.state.menuState)
    }

    render() {
        return (

            <div className={this.state.navbar ? 'navbarActive' : 'navbar'} >

                <nav className='container mx-auto w-screen py-5  z-50'>
                    <div className='flex flex-row justify-between items-center '>
                        <div className='flex w-28 justify-self-start ml-5 md:ml-0' >
                            <AnchorLink offset='100' href='#home'>
                                <LmtLogo />
                            </AnchorLink>
                        </div>
                        <div className='flex lg:hidden outer-container mr-5 relative'>
                            <div >
                                <Menu right pageWrapId={"page-wrap"} isOpen={this.state.menuState} onOpen={() => this.handleMenu()} onClose={() => this.handleMenu()} >
                                    <div onClick={() => this.handleMenu()} role="link" tabIndex={0}
                                    >
                                        <AnchorLink offset='100' href='#services'>
                                            <div className='navItem'>Services</div>
                                        </AnchorLink>
                                    </div>
                                    <div onClick={() => this.handleMenu()} role="link" tabIndex={0}
                                    >
                                        <AnchorLink offset='100' href='#about'>
                                            <div className='navItem'>About me</div>
                                        </AnchorLink>
                                    </div>
                                    <div onClick={() => this.handleMenu()} role="link" tabIndex={0}
                                    >
                                        <AnchorLink offset='100' href='#contact'>
                                            <div className='navItem'>Contact</div>
                                        </AnchorLink>
                                    </div>



                                </Menu>
                            </div>
                        </div>
                        <div className='hidden lg:flex flex-row justify-between gap-20 navBar items-center'>
                            <AnchorLink offset='100' href='#about'>
                                <div className='navItem'>About</div>
                            </AnchorLink>
                            <AnchorLink offset='100' href='#services'>
                                <div className='navItem'>Services</div>
                            </AnchorLink>

                            <AnchorLink offset='100' href='#contact'>
                                <PrimaryButton text="Contact" />
                            </AnchorLink>

                        </div>
                    </div>
                </nav>
            </div>
        );
    }


}

export default NavBar;