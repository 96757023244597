import React, { Component } from 'react';
import SquareLinkedIn from './images/linkedinsquare';
import SquareMail from './images/mailsquare';
class ContactBar extends Component {
    state = {}
    render() {
        return (
            <div className='yellowStripe flex flex-row justify-end gap-5'>
                <a href='mailto:lucian@lmtbiz.com'><SquareMail /></a>
                <a href='https://be.linkedin.com/in/lucian-ioan-79a22930'><SquareLinkedIn /></a>
            </div>
        );
    }
}

export default ContactBar;