import React, { Component } from 'react';
class ServiceBlockOpposite extends Component {
  
    render() {
        return (
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                <div className='flex flex-col order-2 md:order-1 justify-between items-start'>
                    <div>
                        <h3>
                            {this.props.title}
                        </h3>
                        <p>
                            {this.props.p}

                        </p>
                    </div>
                    {/* <OutlinedButton text={this.props.buttonText} />*/} 
                </div>
                <div className='relative order-1 md:order-2 md:h-80'>
                    <div className='flex relative h-full w-full primaryBackground overflow-hidden justify-end'>
                        <img src={this.props.img} alt="management consulting people table" className='w-11/12 h-full object-cover box-border ' />
                    </div>
                </div>


            </div>
        );
    }
}

export default ServiceBlockOpposite;