import React from 'react';

const LmtLogo = () =>  {



    

        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.68 133.45" width="100%" height="100%">

                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <path d="M0,0H25.61V89.8H81.1v20.87H0Z" />
                        <path d="M233.65,20.87H198.24V0h96.44V20.87H259.26v89.8H233.65Z" />
                        <polygon className="cls-1" fill="#264790" points="0 133.45 0 120.67 294.68 120.67 290.2 133.45 0 133.45" />
                        <polygon points="198.24 24.87 194.24 24.87 194.24 20.87 194.24 0 180.34 0 139.55 68.77 98.13 0 76.94 0 76.94 85.8 81.1 85.8 85.1 85.8 85.1 89.8 85.1 110.67 100.97 110.67 100.97 45.69 133.38 98.97 144.93 98.97 177.49 44.27 177.65 110.67 201.68 110.67 201.44 24.87 198.24 24.87" />
                    </g>
                </g>
            </svg>



        );
    
}

export default LmtLogo;