import React, { Component } from 'react';


class ScrollComponent extends Component {
    state = {}
    render() {
        return (

            <div className='flex flex-row justify-evenly items-center'>
                <div className='w-full blueStripe'>
                </div>
                <div className='pl-5 pr-5'>
                    <div className="scroll-downs">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                    </div>
                </div>
                <div className='w-full blueStripe'>
                </div>

            </div>

        );
    }
}

export default ScrollComponent;