

import React, { Component } from 'react';
class PrimaryButton extends Component {

    render() {
        return (<button className='navItem primaryButton'>{ this.props.text }</button>
        );
    }
}

export default PrimaryButton;


