import React, { Component } from 'react';
import ContactBar from './contactbar';

import AboutImage from "./images/aboutImage.jpeg";

class AboutSection extends Component {
    render() {
        return (
            <section>
                <div className='grid grid-flow-row md:grid-cols-2 gap-5'>
                    <div className='flex flex-col justify-evenly'>
                        <div className='relative flex flex-col gap-5'>

                            <h2 className='relative text-4xl md:text-3xl lg:text-5xl z-20'>This is LMT</h2>
                            <div className='yellocTextStripe z-10 md:block hidden'>

                            </div>
                        </div>
                        <img src={AboutImage} alt="Management explaining numbers and forecasts" className="w-full h-full self-center object-cover  mt-5 md:mt-0" />
                        <div className='md:hidden'>
                            <ContactBar />
                        </div>
                    </div>
                    <div className='align-middle items-center md:my-16 pt-5'>
                        <p>
                            LMT is a management consultancy firm that provides practical & tailored solutions to the customer’s needs.
                            Our contribution, after a deep understanding of the relevant local and international business environment, is to help senior management with experienced consultancy services that support organizations to achieve their desired goals.

                            <br /> < br />
                            Our expertise is based primarily on more than 20 years in a very competitive and challenging international business environment.
                            Potential clients are mostly small to mid-size companies looking for assistance in aligning their strategies for long term success.
                        </p>
                    </div>
                </div>
                <div className='hidden md:block'><ContactBar /></div>

            </section>

        );
    }
}

export default AboutSection;


