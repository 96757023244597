import React, { Component } from 'react';
import LmtLogoWhite from './images/lmtLogoWhite';
import PrimaryButtonLight from './PrimaryButtonLight';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Footer extends Component {
    state = {}
    render() {
        return (<footer className='primaryBackground'>
            <div className='min-h-content md:h-40 primaryBackground w-screen items-center flex flex-row'>
                <div className='container grid grid-cols-1 md:grid-cols-2 justify-between mx-auto py-5 md:py-0 gap-5'>
                    <div className='w-28 md:h-28 flex flex-row self-center mx-auto md:mx-0 mb-7 md:mb-0 '>
                        <AnchorLink offset='100' href='#home'>
                            <LmtLogoWhite />
                        </AnchorLink>
                    </div>
                    <div className=' grid grid-cols-1 md:flex md:flex-row md:justify-end gap-10 lg:gap-20 navBar items-center text-center md:text-left'>
                        <AnchorLink offset='100' href='#about'>
                            <div className=' text-white'>About</div>
                        </AnchorLink>
                        <AnchorLink offset='100' href='#services'>
                            <div className='text-white'>Services</div>
                        </AnchorLink>

                        <AnchorLink offset='100' href='#contact'>
                            <PrimaryButtonLight text="Contact" />
                        </AnchorLink>
                        <div>
                        </div>
                    </div>
                </div>
                
            </div>
<div className='flex flex-row justify-center text-white mb-5'>Site by&nbsp; <a target="_blank" href="https://www.subliweb.be/"> Subliweb.be</a></div>
       </footer>);
    }
}

export default Footer;