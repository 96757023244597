import React from 'react';


const Mission = () => {
    //TODO: Create comp
    return (<div className='container mx-auto flex flex-col'>
        <h3 className=' text-center'>MISSION</h3>
        <h4 className='italic text-center'>
            Advise clients on their management, business planning, sales & marketing strategies.
            <br />
            Assist in the development of short- and long-term execution plans that promote successful growth.
        </h4>
    </div>)
}

export default Mission;