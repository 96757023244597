import * as React from "react"
import Hero from "../components/hero/hero"
import NavBar from "../components/navbar"
import ScrollComponent from "../components/ScrollComponent"
import Services from "../components/services/services"
import Spacing from "../components/spacing"
import '../styles/global.scss'
import AboutSection from "../components/about"
import Contact from "../components/contact"
import Footer from "../components/footer"
import { Helmet } from "react-helmet";
import Mission from "../components/mission"
import Vision from "../components/vision"
// markup
const IndexPage = () => {
  return (
    <html lang="en">

      <div className="overflow-hidden outer-container">
        <Helmet>
          <title>LMT | Customer-driven consulting firm that drives growth</title>
          <meta charSet="utf-8" />
          <meta property="og:url" content="lmtbiz.com" />
          <meta property="og:site_name" content="LMT | Customer-driven consulting firm that drives growth" />
          <meta property="og:locale" content="nl_BE" />
          <meta property="og:type" content="website" />
          <meta name="description" content="LMT is a customer-driven consulting firm that helps organizations drive growth, adapt to changes and create value." />‍

        </ Helmet>
        <body>

          <NavBar />
          <div className=" px-6 md:px-0">

            <main className="container mx-auto overflow-x-hidden " id="page-wrap" >
              <title>Home Page</title>
              <div className="h-10">
              </div>
              <section id='home'>
                <div className="mt-7">
                  <Hero />
                </div>
              </section>
              <Spacing />
              <ScrollComponent />
              <Spacing />
              <section id='about'>
                <AboutSection />
              </section>
              <Spacing />
              <Vision />
              <Spacing />
              <Mission />
              <Spacing />
              <section id='services'>
                <Services />
              </section>

              <Spacing />
              <section id='contact'>
                <Contact />
              </section>

              <Spacing />

            </main>
          </div>
          <Footer />
        </body>

      </div>
    </html>

  )
}

export default IndexPage
