

import React, { Component } from 'react';
class PrimaryButtonLight extends Component {
   
    render() {
        return (<button className='primaryButtonLight'>{ this.props.text }</button>
        );
    }
}

export default PrimaryButtonLight;


